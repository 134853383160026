import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AddEvent, CancelEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { ODataService } from 'src/app/dashboard/services/odata.service';
import { ToasterService } from 'src/app/shared/notification-toast/toaster.service';
import { AppSettings } from 'src/assets/config/app-settings';
import { Number } from 'src/app/core/models/customer.model';
import { SessionService } from 'src/app/core/services/session.service';
import { EventsService } from 'src/app/core/services/events.service';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NumberGridService extends ODataService {
  private editedRowIndex: number | undefined;
  public formGroup: FormGroup | undefined;

  constructor(
    http: HttpClient,
    private toaster: ToasterService,
    private settings: AppSettings,
    private session: SessionService,
    private events: EventsService
  ) {
    const url = `${settings.apiBaseUrl}/api/numbers/odata`;
    super(http, url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'createdAt', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };

    this.mapper = (data: any[]) => {
      data.map((x) => {
        x.createdAt = new Date(x.createdAt);
      });
      return data;
    };
  }

  public addHandler(args: AddEvent): void {
    this.closeEditor(args.sender);
    // define all editable fields validators and default values
    // this.formGroup = new FormGroup({
    //   ProductID: new FormControl(),
    //   ProductName: new FormControl("", Validators.required),
    //   UnitPrice: new FormControl(0),
    //   UnitsInStock: new FormControl(
    //     "",
    //     Validators.compose([
    //       Validators.required,
    //       Validators.pattern("^[0-9]{1,3}"),
    //     ])
    //   ),
    //   Discontinued: new FormControl(false),
    // });
    // show the new row editor, with the `FormGroup` build above
    args.sender.addRow(this.formGroup);
  }

  public editHandler(args: EditEvent): void {
    // define all editable fields validators and default values
    const { dataItem } = args;
    this.closeEditor(args.sender);

    this.formGroup = new FormGroup({
      _id: new FormControl(dataItem._id),
      name: new FormControl(dataItem.name, Validators.required),
    });

    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const number = formGroup.value;
    this.patchNumber(number).subscribe((response) => {
      this.read(() => {
        const index = this.session.user!.customer!.numbers?.findIndex((x) => x._id === response.number!._id);
        this.session.user!.customer!.numbers![index!] = response.number!;
        if(this.session.user!.selectedNumber?._id === response.number!._id) {
          this.session.user!.selectedNumber = response.number!;
        }
        this.formGroup = undefined;
        sender.closeRow(rowIndex);
      });
    });
  }

  private patchNumber(number: Number) {
    const url = `${this.settings.apiBaseUrl}/api/numbers/patch`;
    return this.http.patch<{ number: Number }>(url, number);
  }

  public updateDefaultNumber(number: Number) {
    const request = {
      _id: number._id,
      customerId: number.customer,
    };
    const url = `${this.settings.apiBaseUrl}/api/numbers/updateDefault`;
    return this.http.patch<{ numbers: Number[] }>(url, request)
      .pipe(tap((response) => {
        this.session.user!.customer!.numbers = response.numbers;
        const defaultNumber = response.numbers.find((number) => number.isDefault);        
        this.session.user!.selectedNumber = defaultNumber;
        this.events.defaultNumberChange(defaultNumber?._id!);
      }));
  }

  public removeHandler(args: RemoveEvent): void {
    // remove the current dataItem from the current data source,
    // `editService` in this example
    // this.editService.remove(args.dataItem);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}
